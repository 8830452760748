import React from 'react';
import '../css/coming-soon-banner.css'; 
import '../css/webp-image.css';
import '../css/service.css';
import '../css/row-to-item.css';

import Divider from '../components/Divider';
import OutlinedButton from '../components/OutlinedButton';
import WebpImage from '../components/WebpImage';
import ListToRows from '../components/ListToRows';
import VideoPlayer from '../components/VideoPlayer';
import SectionHeader from '../components/SectionHeader';
import PictureAndText from '../components/PictureAndText';
import ParallaxBanner from '../components/ParallaxBanner';
import { Services } from '../helperJS/services';
import { Pages } from '../helperJS/enums';
import { offers } from '../helperJS/offers';
import { ItemComponents } from '../helperJS/enums';

const Home = () => {
    return (
        <React.Fragment>
            <ParallaxBanner
                page={Pages.Home}
                altText='Crestview Senior Living rendering'
                titleText='Crestview Senior Living'
                subTextLineOne='Now Accepting Residents'
                subTextLineTwo='Reserve Today!'
            />

            {/* <div className="coming-soon-banner-container">
                <div className="coming-soon-header">Join Our Exlusive Founder's Club Now!</div>
                <div className="coming-soon-subheader">Be one of the first members in our community and enjoy the amazing benefits</div>
                <div className="coming-soon-button-container">
                    <div className="btn btn-primary btn-outline" onClick={() => {openModal()}}>Tell me more!</div>
                </div>
            </div> */}
            <SectionHeader text="The Heartbeat of Retirement Life" />
            <PictureAndText 
                text="If you are looking for a brand-new Senior Living Community, where you can enjoy an outstanding
                lifestyle, Crestview Senior Living is the place for you. Crestview Senior Living provides the heartbeat for
                community life offering residents a variety of amenities including a craft bar, restaurant, meeting room,
                library, movie theater, putting green, to name a few. Our goal is to make sure that all residents enjoy
                their retirement life; for some this could mean doing nothing at all; for others this could mean being
                the center of social interactions, or somewhere in between. At Crestview Senior Living, our choice of
                amenities makes this lifestyle possible.
                newline
                Crestview Senior Living is located in Cabot, Arkansas, which is quiet, convenient and just a short drive
                from Little Rock. Residents can choose between different floor plans suited to their needs."
                imgSrc={require("../assets/home/yoga.jpg")}
                webpSrc={require("../assets/home/yoga.webp")}
                altText="Yoga in Garden"
                textFirst={true}
            />
            <Divider />
            <SectionHeader text="What We Offer" />
            <ListToRows
                itemComponentType={ItemComponents.Service}
                listToConvert={Services}
                numOfColumns={2}
            />
            <div className="service-award-container">
                <div className="service-container">
                    <div className="best-meals-and-dining-container">
                        <div className="best-meals-and-dining">
                            <WebpImage 
                                webpSrc={require("../assets/shared/best-meals-and-dining.webp")}
                                imgSrc={require("../assets/shared/best-meals-and-dining.jpg")}
                                altText="crestview senior living"
                            />
                        </div>
                    </div>
                    <OutlinedButton text="Learn More - Dining" linkTo="/dining" style={{ width: "100%"}} />
                </div>
            </div>
            <Divider />
            <SectionHeader text="Discover Crestview Senior Living" />
            <SectionHeader text="Learn what makes us special." isSubHeader={true} />
            <ListToRows
                itemComponentType={ItemComponents.Offers}
                listToConvert={offers}
                numOfColumns={3}
            />
            <VideoPlayer 
                videoHeaderText="See How Far We've Come!"
                url='https://d86mj0n906ty0.cloudfront.net/crestview-senior-living-cottages.mp4' 
                playing={true}
                muted={true}  
                loop={true}  
            />
        </React.Fragment>
    );
}
 
export default Home;