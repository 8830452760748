import React from 'react';
import '../../css/beeline-tour-modal.css';

import Modal from '@mui/material/Modal';
import WebpImage from '../../components/WebpImage';
import CloseIcon from '@mui/icons-material/Close';

const BeelineTourModal = ({ isOpen, closeModal }) => {
    const handleModalClick = (e) => {
      e.stopPropagation();
    };

    return isOpen && (
      <Modal
        className="beeline-tour-modal animated fadeIn"
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="beeline-tour-modal"
        aria-describedby="beeline-tour"
      >
        <div className="beeline-tour-modal-content" onClick={handleModalClick}>
          <CloseIcon 
            className="close-button-beeline-tour-modal" 
            style={{ fontSize: 50, zIndex: 10000 }}
            onClick={closeModal}
          />
          <div className="beeline-tour-modal-picture-container">
            <WebpImage 
              webpSrc={require("../../assets/modals/beeline-tour-modal.webp")}
              imgSrc={require("../../assets/modals/beeline-tour-modal.jpg")}
              altText="crestview senior living"
            />
          </div>
        </div>
      </Modal>
    );
}
  
export default BeelineTourModal;