import React, { Component, useEffect } from 'react';
import { Pages } from '../helperJS/enums';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '../css/App.css';

import Footer from './Footer';
import Home from '../pages/Home';
import Error from '../pages/Error';
import Dining from '../pages/Dining';
import Gallery from '../pages/Gallery';
import Careers from '../pages/Careers';
import Contact from '../pages/Contact';
import PageButtons from './PageButtons';
import StickyHeader from './StickyHeader';
import FloorPlans from '../pages/FloorPlans';
import MemoryCare from '../pages/MemoryCare';
import RespiteCare from '../pages/RespiteCare';
import Covid19Page from '../pages/Covid19Page';
import AssistedLiving from '../pages/AssistedLiving';
import ReactHelmetWrapper from './ReactHelmetWrapper';
import BeelineTourModal from './modals/BeelineTourModal';
import ScrollToTopOnPageLoad from './ScrollToTopOnPageLoad';
import IndependentCottages from '../pages/IndependentCottages';

class App extends Component {
	constructor() {
		super();
		console.log('Prerendering:', typeof navigator === 'undefined');
		console.log('Is React-Snap Prerendering:', !!window.__REACT_SNAP__);
		console.log("react_snap", window.location.search.includes('react_snap=true'));
		// Check if running in a browser or during react-snap build
		const isPrerender = typeof navigator === 'undefined';

		this.state = {
			isMobile: window.innerWidth <= 576,
			isModalOpen: false,
			isCovidAlertOpen: true,
			hasHydrated: false,
		}

		this.resize = this.resize.bind(this);
		this.getPage = this.getPage.bind(this);
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.closeCovidAlert = this.closeCovidAlert.bind(this);
	}

	componentDidMount() {
		// Ensure the modal only opens after hydration
		console.log("window.__REACT_SNAP__", window.__REACT_SNAP__)
		console.log("window !== 'undefined", window !== 'undefined')
		console.log("navigator.userAgent", navigator.userAgent)
		const isPrerendering = typeof navigator !== 'undefined' && navigator.userAgent === 'ReactSnap';
		if (!isPrerendering) {
			this.setState({ hasHydrated: true });
			
			// Add a delay before opening the modal (to simulate user interaction)
			setTimeout(() => {
				this.setState({ isModalOpen: true });
			}, 1000);
		}
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		if(window.innerWidth <= 576) {
			this.setState({isMobile: true});
		} else {
			this.setState({isMobile: false});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return this.state.firstVisit !== nextState.firstVisit
			|| this.state.isModalOpen !== nextState.isModalOpen
			|| this.state.isCovidAlertOpen !== nextState.isCovidAlertOpen;
	}

	getPage() {
		let curPath = window.location.pathname;
		curPath.toLowerCase();
		switch(curPath) {
			case "/": return Pages.Home
			case "/tourModal": return Pages.TourModal
			case "/assisted-living": return Pages.AssistedLiving
			case "/memory-care": return Pages.MemoryCare
			case "/respite-care": return Pages.RespiteCare
			case "/independent-cottages": return Pages.IndependentCottages
			case "/floor-plans": return Pages.FloorPlans
			case "/contact": return Pages.ContactUs
			case "/careers": return Pages.Careers
			case "/covid-19": return Pages.Covid19
			case "/dining": return Pages.Dining
			default: return Pages.Home
		}
	}

	openModal = () => {
		this.setState({ isModalOpen: true })
	}

	closeModal = () => {
		this.setState({ isModalOpen: false });
	}

	closeCovidAlert = () => {
		this.setState({ isCovidAlertOpen: false })
	}

	render() {
		const {
			isModalOpen,
			isCovidAlertOpen,
			hasHydrated
		} = this.state;

		return (
			<BrowserRouter>
				{hasHydrated && isModalOpen && (
					<BeelineTourModal
						isOpen={isModalOpen}
						closeModal={this.closeModal}
					/>
				)}
				{/* { this.state.isMobile 
					? <MobileDrawer 
						isCovidAlertOpen={isCovidAlertOpen} 
						closeCovidAlertBanner={this.closeCovidAlert}
					/> 
					: <StickyHeader 
						isCovidAlertOpen={isCovidAlertOpen}
						closeCovidAlertBanner={this.closeCovidAlert}
					/>
				} */}

				<StickyHeader 
					isCovidAlertOpen={isCovidAlertOpen}
					closeCovidAlertBanner={this.closeCovidAlert}
				/>

				<ReactHelmetWrapper page={this.getPage} />
				<Switch>
					<Route path="/" render={() => <Home />} exact/>
					<Route path="/assisted-living" component={AssistedLiving}/>
					<Route path="/memory-care" component={MemoryCare}/>
					<Route path="/respite-care" component={RespiteCare}/>
					<Route path="/independent-cottages" component={IndependentCottages}/>
					<Route path="/gallery" component={Gallery}/>
					<Route path="/floor-plans" render={FloorPlans}/>
					<Route path="/contact" render={() => <Contact />} />
					<Route path="/careers" component={() => <Careers />}/>
					<Route path="/covid-19" component={Covid19Page}/>
					<Route path="/dining" component={Dining}/>
					<Route component={Error}/>
				</Switch>
				<ScrollToTopOnPageLoad />
				{ !this.state.isMobile 
					&& <PageButtons 
						openModal={this.openModal} 
					/>
				}
				<Footer />
			</BrowserRouter>
		);
	}
}
 
export default App;